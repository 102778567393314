/**
 * Created by sneaky on 23/01/17.
 */
// Bootstrap wants jQuery global
window.jQuery = $ = require('jquery');

// Load libraries
// Bootstrap;
var bootstrap = require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap.js');
var slickslider = require('../../node_modules/slick-carousel/slick/slick.js');
var sneakpeek = require('sneakpeek');
var aos = require('aos');

/*
var TweenLite = require('../plugins/gsap/TweenLite.min.js');

window.tools = require('../plugins/slider-revolution/rs-plugin/js/jquery.themepunch.tools.min.js');
window.revolution = require('../plugins/slider-revolution/rs-plugin/js/jquery.themepunch.revolution.min.js');
window.tweenlite = require('../plugins/gsap/TweenLite.min.js');
*/

// Polyfill for img cover on IE.
var objectFitImages = require('object-fit-images');


// TODO: Add depedencys from older site. Jquery validat and others.
/*
<!--- TODO: Commentend out all javascript with cfm comments, to keep as referens when creating the main.js, clean up later. --->
<!--- Jquery and Bootstrap --->
<!---<script src="#application.site.url#/lib/plugins/jquery/jquery-1.11.1.min.js"></script>--->
    <!--- TODO: add validate to main script --->
<!---<script src="#application.site.url#/lib/plugins/jquery-validate/jquery.validate.min.js"></script>--->
    <!---<script src="#application.site.url#/lib/plugins/bootstrap/3.2.0/js/bootstrap.min.js"></script>--->


    <!--- TweenLite, CSSPlugin, and EasePack --->
<!---<script src="#application.site.url#/lib/plugins/gsap/plugins/CSSPlugin.min.js"></script>--->
    <!---<script src="http://cdnjs.cloudflare.com/ajax/libs/gsap/latest/easing/EasePack.min.js"></script>--->
    <!---<script src="#application.site.url#/lib/plugins/gsap/TweenLite.min.js"></script>--->

    <!--- Royalslider --->
    <!---<script src="#application.site.url#/lib/plugins/slider-revolution/rs-plugin/js/jquery.themepunch.tools.min.js"></script>--->
    <!---<script src="#application.site.url#/lib/plugins/slider-revolution/rs-plugin/js/jquery.themepunch.revolution.min.js"></script>--->

*/


$( document ).ready(function() {
    var viewportWidth = $(window).width();

    var nativeSelect = false;
    if(viewportWidth < 768){
        nativeSelect = true;
    }

    // Run object fit polyfill
    objectFitImages();

    // Lifeassays menu ======================================
    $('.dropdown-toggle').click(function(event){
        event.preventDefault();
    });
    
    // Show button closes others
    $('#nav-desktop-product-L2').find('.collapse').on('show.bs.collapse', function () {
        console.log('ping2');
        $('#nav-desktop-product-L2').find('.collapse').removeClass('in');
        $('#nav-desktop-product-L1').find('a.dropdown-toggle').addClass('collapsed');
//        $('#nav-desktop-product-L2').find('a.dropdown-toggle').hide();
        window.scrollTo(0,0);

    })

    // Close button closes all
    $('.closeBtn').click(function () {
        $('#nav-desktop-product-L2').find('.collapse').collapse('hide');
    });
    

    // Init aos. (Animate on scroll)
    aos.init({
        offset: 50,
        delay: 50,
        duration: 850
    });

    // Hide with sneakpeek on scroll
    sneakpeek(document.getElementById("main-navigation"));
    // Hide any open dropdowns. WHen menu is hidden
    sneakpeek.emitter.on('hide', function(){
        $('#nav-desktop-product-L2').find('.collapse').collapse('hide');
    });

  //  console.log('yeah');

//    $(".slider.636D0F82-9D75-8829-8BC253D563F13650 .tp-banner").hide();

    // Revolution slider test
    $(".slider.636D0F82-9D75-8829-8BC253D563F13650 .tp-banner").revolution({
        delay:9000,
        startwidth:1170,
        startheight:400,
        hideThumbs:10,
        navigationType:"none",
        onHoverStop:"off",
        videoJsPath:"rs-plugin/videojs/"
    });

   
    



    

});
